<template>
  <div id="root">
    <div id="base-view" class="_page_1p3f6_1">
      <RouterView />
    </div>
    <!-- <LiveChat
      v-if="['prime', 'cms'].includes(this.product)"
      class="livechat-custom"
      @ready="setCustomClassOnLiveChat()"
      @visibility-changed="handleVisibilityChange"
    /> -->
    <div
      ref="supportWhatsapp"
      class="app-default-whatsapp-button"
      style=""
      @click="handleWhatsAppClick"
    >
      <img :src="whatsappIcon" alt="WhatsApp Support" class="app-default-whatsapp-logo" />
    </div>
  </div>
</template>

<script>
import './assets/font.css'
import './assets/price-d1055937.css'
import './assets/section-header-6260c393.css'
import './assets/skeleton-eb00918e.css'
import './assets/index-4f599abb.css'
import './assets/currency-name-ac58157e.css'
import './assets/segmented-3eeef3c9.css'
import './assets/confirm-code-form-0fde0d24.css'
import './assets/alert-136e1162.css'
import './assets/price-change-tag-e3eef80e.css'
import './assets/modal-52241758.css'
import './assets/information-ed9200d3.css'
import './assets/market-home-97a4ddc6.css'
import './assets/market-798916b5.css'
import './assets/market-grid-f6ab7510.css'
import './assets/empty-99f01dd9.css'
import './assets/radio-group-2f7d177e.css'
import './assets/trade-5b6bcbb9.css'
import './assets/card-8907dbea.css'
import './assets/form-item-ce281b63.css'
import './assets/dashboard-abb70cf6.css'
import './assets/tabs-1c6dbce1.css'
import './assets/descriptions-dd8ebde7.css'
import './assets/row-6f9413a2.css'
import './assets/trade-menu-button-f4d99802.css'
import './assets/avatar-d4b7f725.css'
import './assets/icon-button-18aedd02.css'
import './assets/market-card-3f1e66ae.css'
import './assets/input.css'
import './assets/badge-d05a30df.css'
import './assets/cancel-order-8cb7b90a.css'
import './assets/infinite-scroll-list-b5da48b0.css'
import './assets/order-list-510f5522.css'
import './assets/order-1c773c3d.css'
import './assets/divider-81ec301a.css'
import './assets/disclosure-ac3f6853.css'
import './assets/profile-home-d88ab337.css'
import './assets/transaction-list-ca4c5037.css'
import './assets/use-service-unavailable-9b4737bb.css'
import './assets/send-0aeb04cc.css'
import './assets/register-4d25c7c2.css'

import { RouterView } from 'vue-router'
import { LiveChatWidget } from '@livechat/widget-vue'
import LiveChat from './components/LiveChat.vue'
import settings from './settings'
import WhatsAppIcon from './assets/icons/WhatsApp.svg'
import { primePhoneNumber } from './main'

export default {
  components: {
    RouterView,
    LiveChatWidget,
    LiveChat
  },
  data() {
    return {
      liveChatPosition: 'minimized',
      product: settings.PRODUCT,
      whatsappIcon: WhatsAppIcon,
      productWebsite: settings.PRODUCT_WEBSITE,
      productTitle: settings.PRODUCT_TITLE,
      token: ''
    }
  },
  watch: {
    $route: function () {
      this.setCustomClassStyle()
    }
  },
  methods: {
    setCustomClassStyle() {
      if (this.product == 'arvand') {
        document.documentElement.style.setProperty('--support-whatsapp-display', 'none')
      } else if (
        !(Object.keys(this.$route.meta).includes('liveChat') && this.$route.meta.liveChat)
      ) {
        document.documentElement.style.setProperty('--live-chat-display', 'none')
        document.documentElement.style.setProperty('--support-whatsapp-display', 'none')
      } else {
        document.documentElement.style.setProperty('--live-chat-display', 'unset')
        document.documentElement.style.setProperty('--support-whatsapp-display', 'flex')
        if (this.liveChatPosition == 'maximized') {
          document.documentElement.style.setProperty('--live-chat-bottom', '0px')
          document.documentElement.style.setProperty('--support-whatsapp-bottom', '20px')
        } else {
          document.documentElement.style.setProperty(
            '--live-chat-bottom',
            this.$route.meta.liveChatBottom ?? '0px'
          )
          document.documentElement.style.setProperty(
            '--support-whatsapp-bottom',
            this.$route.meta.liveChatBottom ?? '20px'
          )
        }
      }
    },
    setCustomClassOnLiveChat() {
      var d = document.getElementById('chat-widget-container')
      if (d == null) return
      d.classList.add('livechat-custom')
    },
    handleVisibilityChange(event) {
      if (event.visibility == 'minimized') this.liveChatPosition = 'minimized'
      else if (event.visibility == 'maximized') this.liveChatPosition = 'maximized'
      this.setCustomClassStyle()
    },
    handleWhatsAppClick() {
      const url = this.generateWhatsAppUrl()
      window.open(url)
    },
    generateWhatsAppUrl() {
      const baseUrl = 'https://wa.me/'
      const defaultNumber =
        this.product === 'prime'
          ? primePhoneNumber.replace(/\s+/g, '').replace('+', '')
          : this.product === 'cms'
          ? '+971589624850'
          : '+971522205216' // Default WhatsApp number
      const anonymousMessage = `سلام\nHello. I came from ${this.productWebsite.toLowerCase()}`
      return `${baseUrl}${defaultNumber}?text=${encodeURIComponent(anonymousMessage)}`
    }
  }
}
</script>

<style>
:root {
  --live-chat-bottom: 0px;
  --live-chat-display: unset;
  --support-whatsapp-bottom: 20px;
  --support-whatsapp-display: flex;
}

.livechat-custom {
  bottom: var(--live-chat-bottom) !important;
  display: var(--live-chat-display) !important;
}

.app-default-whatsapp-button {
  position: fixed;
  bottom: var(--support-whatsapp-bottom) !important;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent; /* Transparent background */
  display: var(--support-whatsapp-display) !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10000;
  transition: transform 0.3s ease;
}

.app-default-whatsapp-button:hover {
  transform: scale(1.1);
}

.app-default-whatsapp-logo {
  width: 50px;
  height: 50px;
}
</style>
