import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Vue3Toastify, { toast } from 'vue3-toastify'
import VueTelInput from 'vue3-tel-input'
import VOtpInput from 'vue3-otp-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import * as Sentry from '@sentry/vue'
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'
import settings from './settings'
import '../sw'
import PN from 'persian-number/src/persianNumber'

var qs = require('qs')

const app = createApp(App)

app.use(Vue3Toastify, {
  autoClose: 3000,
  position: toast.POSITION.TOP_CENTER,
  theme: toast.THEME.COLORED
})
app.use(VueTelInput)

app.use(Vue3PersianDatetimePicker, {
  name: 'CustomDatePicker',
  props: {
    format: 'YYYY-MM-DD HH:mm',
    displayFormat: 'jYYYY-jMM-jDD',
    editable: false,
    inputClass: 'form-control my-custom-class-name',
    placeholder: 'Please Select a date',
    altFormat: 'YYYY-MM-DD HH:mm',
    color: '#00acc1',
    autoSubmit: false
  }
})

let axiosRequestHandler = (config) => {
  const { $cookies } = app.config.globalProperties
  // Add a custom header to the request
  config.headers['Authorization'] = 'Bearer ' + $cookies.get('token')
  return config
}

let axiosErrorHandler = function (error) {
  if (error.config.errorHandling == false) {
  } else if (error.code == 'ERR_NETWORK') {
    toast.error('Network Error!')
  } else if (error.response.status === 401) {
    const { $cookies } = app.config.globalProperties
    $cookies.remove('token')
    // console.log("UNAUTHORIEZED", router, router.currentRoute._value, router.currentRoute._value.meta.needAuth)
    if (router.currentRoute.value.path !== '/' && router.currentRoute.value.meta.needAuth !== false)
      router.push({ name: 'Login' })
  } else if (error.response.status >= 500 && error.response.status < 600) {
    toast.error('Server Error!')
  } else if (
    error.response.data instanceof Object &&
    'code' in error.response.data &&
    'ignoreErrorCodes' in error.config &&
    error.config.ignoreErrorCodes.includes(error.response.data.code)
  ) {
  } else if ([400, 402, 403, 404, 405, 406].includes(error.response.status)) {
    if (error.response.data.message) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        theme: toast.THEME.COLORED,
        toastId: error.response.data.message
      })
    }
  }
  return Promise.reject(error)
}

axios.interceptors.response.use((response) => response, axiosErrorHandler)

axios.interceptors.request.use(axiosRequestHandler)

const axios_include_timezone = axios.create()
app.config.globalProperties.axios_include_timezone = axios_include_timezone

axios_include_timezone.interceptors.request.use((config) => {
  var config = axiosRequestHandler(config)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  config.headers['UserTimeZone'] = userTimeZone
  config.params = {
    ...config.params,
    UserTimeZone: userTimeZone
  }
  return config
})

axios_include_timezone.interceptors.response.use((response) => response, axiosErrorHandler)

export const filters = {
  persianToEnglishDigits(txt) {
    if (!(typeof txt == 'string')) return txt
    let persian_digits_map = {
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9',
      '۰': '0'
    }

    let new_txt = ''

    for (let c of txt) {
      if (c in persian_digits_map) new_txt = new_txt + persian_digits_map[c]
      else new_txt = new_txt + c
    }

    return new_txt
  },
  formatPhoneNumber(phoneNumber) {
    if (phoneNumber == null) return null

    return phoneNumber.replace('+98', '0')
  },
  prettyPositiveInteger(number) {
    number = number.toString()
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  prettyInteger(number) {
    number = number.toString()
    if (number.includes('-')) {
      number = number.replace('-', '')
      return '-' + app.config.globalProperties.$filters.prettyPositiveInteger(number)
    }
    return app.config.globalProperties.$filters.prettyPositiveInteger(number)
  },
  prettyNumber(number) {
    number = number.toString()
    if (number.includes('.')) {
      var parts = number.toString().split('.')
      parts[0] = app.config.globalProperties.$filters.prettyInteger(parts[0])
      return parts[1] === '' ? parts[0] : parts.join('.')
    }
    return app.config.globalProperties.$filters.prettyInteger(number)
  },
  formatDecimal(decimal, digitAfterDecimal = 8, floor = false) {
    if (!decimal && decimal !== 0) return ''
    if (decimal === null || decimal === '') return ''
    if (floor) {
      const factor = Math.pow(10, digitAfterDecimal)
      return Math.floor(parseFloat(decimal) * factor) / factor
    }
    return Number(parseFloat(decimal).toFixed(digitAfterDecimal))
  },
  prettyAndFormatDecimal(decimal, digitAfterDecimal = 8, floor = false) {
    return this.prettyNumber(this.formatDecimal(decimal, digitAfterDecimal, floor))
  },
  formatNumberString(numberStr, allowedDecimals = 20) {
    if (!numberStr.includes('.')) return numberStr

    let decimalPlaces = numberStr.length - numberStr.indexOf('.') - 1
    if (decimalPlaces > allowedDecimals)
      return numberStr.slice(0, -(decimalPlaces - allowedDecimals))
    return numberStr
  },
  numberToString(number) {
    let newValue = number.toString()
    if (newValue.includes('e')) {
      let pw = -Number(newValue.split('e')[1])
      let meanPart = newValue.split('e')[0]
      newValue = this.move(meanPart, pw)
    }
    return newValue
  },
  truncateDecimal(decimalStr, decimalPlaces) {
    if (!decimalStr.includes('.')) return decimalStr

    return decimalStr.slice(0, decimalStr.indexOf('.') + decimalPlaces + 1)
  },
  truncateIntegerPart(decimalStr, integerPartLength) {
    if (decimalStr.includes('.'))
      return (
        this.truncateIntegerPart(decimalStr.slice(0, decimalStr.indexOf('.')), integerPartLength) +
        decimalStr.slice(decimalStr.indexOf('.'))
      )

    if (decimalStr.length <= integerPartLength) return decimalStr
    return decimalStr.slice(decimalStr.length - integerPartLength)
  },
  decimalPartLength(decimalStr) {
    if (!decimalStr.includes('.')) return 0

    return decimalStr.slice(decimalStr.indexOf('.') + 1).length
  },
  integerPartLength(decimalStr) {
    if (decimalStr.includes('.'))
      return this.integerPartLength(decimalStr.slice(0, decimalStr.indexOf('.')))

    return decimalStr.length
  },
  move(number, position) {
    let res = ''
    if (position > 0) {
      res = this.moveRight(number, position)
    } else res = this.moveLeft(number, -position)

    while (res.includes('.') && (res.slice(-1) == '0' || res.slice(-1) == '.'))
      res = res.slice(0, -1)

    return res
  },
  moveRight(number, position) {
    if (!number.includes('.')) number = number + '.0'

    while (position) {
      let pointPosition = number.indexOf('.')
      let next = number[pointPosition - 1]
      number = this.replaceAt(number, pointPosition, next)
      number = this.replaceAt(number, pointPosition - 1, '.')

      if (number[0] == '.') number = '0' + number
      position -= 1
    }

    return number
  },
  moveLeft(number, position) {
    if (!number.includes('.')) number = number + '.0'

    while (position) {
      let pointPosition = number.indexOf('.')
      let next = number[pointPosition + 1]
      number = this.replaceAt(number, pointPosition, next)
      number = this.replaceAt(number, pointPosition + 1, '.')
      if (number.slice(-1) == '.') number = number + '0'
      position -= 1
    }
    return number
  },
  replaceAt(str, index, replacement) {
    return str.substring(0, index) + replacement + str.substring(index + replacement.length)
  },
  removeDecimalPart(decimal) {
    return Math.trunc(decimal)
  },
  mask(text, maxLength = 7, maxStarCount = 5) {
    if (text === undefined || text === null) return text
    // if (text.length <= maxLength * 2 + maxStarCount) return text
    let result = ''
    let starCount = 0
    for (let i = 0; i < text.length; i++) {
      if (i < maxLength) result += text[i]
      else if (i >= text.length - maxLength) result += text[i]
      else if (starCount < maxStarCount) {
        result += '*'
        starCount += 1
      }
    }
    return result
  },
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  camelize(str) {
    let camelCase = str
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
    return this.capitalize(camelCase)
  },
  camelToSnakeCase(str) {
    var result = str.replace(/([A-Z])/g, ' $1')
    result = result.slice(1)
    return result.split(' ').join('-').toLowerCase()
  },
  splitCamelCase(str) {
    return str.split(/(?=[A-Z])/).join(' ')
  },
  generateUUID() {
    var d = new Date().getTime() //Timestamp
    var d2 =
      (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  },
  removeNullAndEmptyValues(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value === null) return acc
      if (value === '') return acc
      if (
        value &&
        value.constructor === Object &&
        Object.keys(this.removeNullAndEmptyValues(value)).length === 0
      )
        return acc
      acc[key] = value
      return acc
    }, {})
  },
  uniqueByField(array, fieldLambda) {
    const seen = new Set()
    return array.filter((item) => {
      const value = fieldLambda(item)
      if (seen.has(value)) {
        return false
      }
      seen.add(value)
      return true
    })
  },
  copyToClipboard(text) {
    // create a temporary textarea element
    const textarea = document.createElement('textarea')
    textarea.value = text

    // make the textarea invisible
    textarea.style.position = 'fixed'
    textarea.style.top = '-9999px'

    // add the textarea to the document
    document.body.appendChild(textarea)

    // select the text in the textarea
    textarea.select()

    // copy the text to the clipboard
    document.execCommand('copy')

    // remove the textarea from the document
    document.body.removeChild(textarea)
  },
  datetimeToString(datetime) {
    let startDate = datetime.toISOString()
    startDate = startDate.replace(' ', 'T')
    return startDate
  },
  routeIsAdmin() {
    return router.currentRoute.value.matched.find((match) => match.name == 'admin') != undefined
  },
  getTimeDiff(date1, date2) {
    return Math.abs(Math.floor((date2.getTime() - date1.getTime()) / 1000))
  },
  getBiggestTimeDiff(date1, date2) {
    const diffInSeconds = this.getTimeDiff(date1, date2)
    const units = [
      { label: 'year', seconds: 365 * 24 * 60 * 60 },
      { label: 'month', seconds: 30 * 24 * 60 * 60 },
      { label: 'week', seconds: 7 * 24 * 60 * 60 },
      { label: 'day', seconds: 24 * 60 * 60 },
      { label: 'hour', seconds: 60 * 60 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 }
    ]

    for (let unit of units) {
      const amount = Math.floor(diffInSeconds / unit.seconds)
      if (amount >= 1) {
        return `${amount} ${unit.label}${amount > 1 ? 's' : ''}`
      }
    }
    return '0 seconds'
  },
  deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true // Same reference
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false // Primitive values or null
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length !== keys2.length) {
      return false
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false
      }
    }
    return true
  }
}

export const fileUtils = {
  imgTypes: ['png', 'jpg', 'jpeg', 'jfif'],
  docTypes: ['pdf', 'html'],
  textTypes: ['txt'],
  getFileNameWithExtension(path) {
    const lastSlashIndex = path.lastIndexOf('/')
    if (lastSlashIndex !== -1) {
      const fileNameWithExtension = path.substring(lastSlashIndex + 1)
      const parts = fileNameWithExtension.split('.')
      return {
        name: parts.slice(0, parts.length - 1).join('.'),
        extension: parts[parts.length - 1].toLowerCase()
      }
    } else {
      const parts = path.split('.')
      return {
        name: parts.slice(0, parts.length - 1).join('.'),
        extension: parts[parts.length - 1].toLowerCase()
      }
    }
  },
  getExtension(path) {
    const fileNameWithExtension = this.getFileNameWithExtension(path)
    return fileNameWithExtension?.extension
  },
  isImage(type) {
    return this.imgTypes.includes(type.toLowerCase())
  },
  isDocument(type) {
    return this.docTypes.includes(type.toLowerCase())
  },
  isTextFile(type) {
    return this.textTypes.includes(type.toLowerCase())
  }
}

export const numberUtils = {
  numberInWords(amount, asset) {
    return (
      PN.convert(filters.formatDecimal(amount, asset.quantityDecimals)) +
      ' ' +
      this.englishToPersianAssets(asset.symbol)
    )
  },
  englishToPersianAssets(symbol) {
    let persian_assets_map = {
      USDT: 'تتر',
      BLC: 'بالانس',
      USD: 'دلار',
      IRT: 'تومان',
      AED: 'درهم',
      GBP: 'پوند',
      CAD: 'دلار کانادا',
      EUR: 'یورو'
    }
    return persian_assets_map[symbol] ?? symbol
  }
}

export const timeUtils = {
  toCountdown(diffInMs) {
    let countdown = '00:00:00'
    if (diffInMs <= 0) {
      return countdown
    }

    const hours = Math.floor(diffInMs / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0')
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0')

    countdown = `${hours}:${minutes}:${seconds}`
    return countdown
  }
}

app.config.globalProperties.$filters = filters

let instance = null

class RouteHistorySingleton {
  constructor() {
    if (instance) {
      return instance
    }
    this.routeHistory = []
    instance = this
  }

  pushRoute(from) {
    this.routeHistory.push(from)
  }
}

const routeHistorySingleton = new RouteHistorySingleton()
const DEFAULT_TITLE = settings.PRODUCT_TITLE

router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE
  if (to.meta.title) {
    document.title = to.meta.title
  }
  routeHistorySingleton.pushRoute(from)
  const { $cookies } = app.config.globalProperties
  // console.log("ROUTING", router.currentRoute)
  // console.log("FROM", from)
  // console.log("TO", to)
  if (!((to.meta ?? { needAuth: true }).needAuth ?? true)) {
    next()
  } else {
    if ($cookies.get('token') == null) next({ name: 'Login', query: { redirect: to.fullPath } })
    else next()
  }
  if (to.name != from.name)
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 750)
})

export const historyMixin = {
  data() {
    return {
      routeHistory: routeHistorySingleton.routeHistory
    }
  }
}

Sentry.init({
  app,
  dsn:
    process.env.NODE_ENV == 'production'
      ? 'https://b6b0e6fea7ee0c48f1aad53748612df1@o4508370794315776.ingest.de.sentry.io/4508370796019792'
      : '',
  skipBrowserExtensionCheck: true,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  release: 'first-local',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mixin(historyMixin)

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(app.config.globalProperties.$filters.removeNullAndEmptyValues(params), {
    encode: false, // Prevent percent-encoding
    allowDots: true // Enable dot notation for nested objects
  })
}
axios_include_timezone.defaults.paramsSerializer = axios.defaults.paramsSerializer

axios.defaults.baseURL = settings.API_URL
axios_include_timezone.defaults.baseURL = axios.defaults.baseURL

app.use(createPinia())
app.use(router)
app.use(VueAxios, axios)
app.use(VueAxios, axios_include_timezone)
app.use(VueCookies)
app.component('v-otp-input', VOtpInput)
app.provide('axios', app.config.globalProperties.axios) // provide 'axios'
app.provide('axios_include_timezone', app.config.globalProperties.axios_include_timezone)
app.mount('#app')

const primePhoneNumber = '+971 58 865 9809'

export { axios_include_timezone }
export { primePhoneNumber }
export default app
