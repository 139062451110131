import axios from 'axios';
import USER from '../../../assets/icons/USER.svg'
import libphonenumber from 'google-libphonenumber'
import { reactive } from 'vue';

export class User {
    static ABAN_EMAIL = "aban@aban.com"
    static ME = null
    static phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();

    static getMe() {
    return new Promise((resolve, reject) => {
      if (User.ME != null) {
        if (User.ME.loggedIn != false) {
          resolve(User.ME)
          return
        }
        User.ME.get()
          .then(() => {
            resolve(User.ME)
          })
          .catch((error) => {
            reject(error)
          })
        return
      }
      User.ME = User.create({
        loggedIn: false,
        phoneNumber: '+989933426890',
        noLiquidation: true,
        isAdmin: false
      })
      User.ME.get().finally(() => {
        resolve(User.ME)
      })
    })
  }

    static getById(id) {
        return new Promise((resolve, reject) => {
            axios.get(
                "user/user/" + id
            )
                .then((response) => {
                    resolve(User.create(response.data))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    static getApiKey() {
        return new Promise((resolve, reject) => {
            axios.get(
                "user/api-credential"
            )
                .then((response) => {
                    resolve(response.data.apiKey)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    static create(data, pathFromRoot = null) {
        if (pathFromRoot == null)
            pathFromRoot = []
        if (data == null)
            return null
        if (data instanceof User)
            return data
        for (let obj of pathFromRoot)
            if (obj instanceof User && obj.id == data.id)
                return obj
        return reactive(new User(data, pathFromRoot))
    }

    constructor(data, pathFromRoot) {
        this.setUp(data, pathFromRoot)
    }

    setUp(data, pathFromRoot) {
        pathFromRoot.push(this)
        this.address = data.address
        this.email = data.email
        this.emailConfirmed = data.emailConfirmed
        this.fullName = data.fullName
        this.id = data.id
        this.phoneNumber = data.phoneNumber
        this.phoneNumberConfirmed = data.phoneNumberConfirmed
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.fullName = data.fullName
        this.isAdmin = data.isAdmin
        this.password = 'fuck'
        this.shortDesc = this.fullName + ' (' + this.phoneNumber + ')'
        this.icon = USER
        this.userName = data.userName
        this.loggedIn = data.loggedIn ?? true
        this.createdAt = data.createdAt
        this.noLiquidation = data.noLiquidation
        this.hasPassword = data.hasPassword
        this.fetcherPromise = null
        this.userSegment = data.userSegment
        this.userSegmentLevel = data.userSegmentLevel
        try {
            this.countryCode = User.phoneNumberUtil.getRegionCodeForNumber(
                User.phoneNumberUtil.parse(
                    this.phoneNumber,
                    ''
                )
            )
        }
        catch {
            this.countryCode = 'US'
        }
        pathFromRoot.pop()
    }

    isAban() {
        return this.email == this.ABAN_EMAIL
    }

    get() {
        if (this.fetcherPromise != null)
            return this.fetcherPromise
        this.fetcherPromise = new Promise((resolve, reject) => {
            axios.get(
                "user/user",
            )
                .then((response) => {
                    this.setUp(response.data, [])
                    resolve(this)
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    this.fetcherPromise = null
                })
        })
        return this.fetcherPromise;
    }

    save() {
        return new Promise((resolve, reject) => {
            axios.put(
                "user/user/" + this.id,
                {
                    address: 'chert',
                    email: this.email,
                    firstname: this.firstName,
                    fullname: this.fullName,
                    lastname: this.lastName,
                    password: 'hey',
                    noLiquidation: this.noLiquidation,
                    phoneNumber: this.phoneNumber
                }
            )
                .then((response) => {
                    this.setUp(response.data, [])
                    resolve(this)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    static list(page, pageSize, options) {
        return new Promise((resolve, reject) => {
            axios.get(
                "user/users",
                {
                    params: {
                        page: page,
                        pageSize: pageSize,
                        ...options
                    }
                }
            )
                .then((response) => {
                    resolve(response.data.data.map(_user => User.create(_user)).filter(_user => _user.id !== 1373 || this.ME.id === 4 || this.ME.id === 302))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    static saveUserSegment(userId, userSegment, userSegmentLevel){
        return new Promise((resolve, reject) => {
            axios.put(
                "user/set-user-segment-type",
                {
                    UserId: userId,
                    UserSegment: userSegment,
                    UserSegmentLevel: userSegmentLevel 
                }
            )
        })
    }
}